// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hyjL2p6wVi8aCns4elop {\n  display: flex;\n  margin-top: auto;\n  left: 0;\n  bottom: 0;\n  background-color: rgb(29, 53, 87);\n  position: fixed;\n  width: 100%;\n  height: 40px;\n}", "",{"version":3,"sources":["webpack://./src/components/Home/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,OAAO;EACP,SAAS;EACT,iCAAiC;EACjC,eAAe;EACf,WAAW;EACX,YAAY;AACd","sourcesContent":[".Footer {\n  display: flex;\n  margin-top: auto;\n  left: 0;\n  bottom: 0;\n  background-color: rgb(29, 53, 87);\n  position: fixed;\n  width: 100%;\n  height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": "hyjL2p6wVi8aCns4elop"
};
export default ___CSS_LOADER_EXPORT___;
