// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._PUxjmEI7IdqAdXbf2jG {\n  background-color: rgb(96, 150, 186);\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  width: 100vw;\n}", "",{"version":3,"sources":["webpack://./src/components/Resume/Resume.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,YAAY;AACd","sourcesContent":[".resume {\n  background-color: rgb(96, 150, 186);\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  width: 100vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resume": "_PUxjmEI7IdqAdXbf2jG"
};
export default ___CSS_LOADER_EXPORT___;
