// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aY3Yv_tggNXxZFB2eMBD ul {\n  display: flex;\n  flex-direction: column;\n}\n\n.aY3Yv_tggNXxZFB2eMBD li img {\n  display: flex;\n  flex-wrap: wrap;\n  border-radius: 10px;\n  border: 2px solid cyan;\n  height: 350px;\n  width: 350px;\n}\n\n.aY3Yv_tggNXxZFB2eMBD li {\n  display: flex;\n  border: 1px red solid;\n}\n\n.aY3Yv_tggNXxZFB2eMBD h2 {\n  padding: 10px;\n  border: 1px red solid;\n}\n\n.aY3Yv_tggNXxZFB2eMBD li h2 a{\n  margin: 5px;\n  margin-left: 10px;\n}\n\n.aY3Yv_tggNXxZFB2eMBD figure {\n  display: flex;\n  border: 1px solid red;\n}\n\n.aY3Yv_tggNXxZFB2eMBD p {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n}", "",{"version":3,"sources":["webpack://./src/components/Projects/Projects.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AACA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB","sourcesContent":[".projects ul {\n  display: flex;\n  flex-direction: column;\n}\n\n.projects li img {\n  display: flex;\n  flex-wrap: wrap;\n  border-radius: 10px;\n  border: 2px solid cyan;\n  height: 350px;\n  width: 350px;\n}\n\n.projects li {\n  display: flex;\n  border: 1px red solid;\n}\n\n.projects h2 {\n  padding: 10px;\n  border: 1px red solid;\n}\n.projects li h2 a{\n  margin: 5px;\n  margin-left: 10px;\n}\n\n.projects figure {\n  display: flex;\n  border: 1px solid red;\n}\n\n.projects p {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projects": "aY3Yv_tggNXxZFB2eMBD"
};
export default ___CSS_LOADER_EXPORT___;
