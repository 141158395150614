// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._S1CySisAsKHvT5_INMU {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background-color: rgb(29, 53, 87);\n  color:  rgb(253, 255, 252);\n  font-family: 'Bungee Shade', cursive;\n  padding: 10px;\n  width: 100%;\n}\n\n._S1CySisAsKHvT5_INMU a {\n  color: rgb(253, 255, 252);\n  font-size: 25px;\n  padding: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Home/Header.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iCAAiC;EACjC,0BAA0B;EAC1B,oCAAoC;EACpC,aAAa;EACb,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,aAAa;AACf","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap');\n\n.Header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background-color: rgb(29, 53, 87);\n  color:  rgb(253, 255, 252);\n  font-family: 'Bungee Shade', cursive;\n  padding: 10px;\n  width: 100%;\n}\n\n.Header a {\n  color: rgb(253, 255, 252);\n  font-size: 25px;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": "_S1CySisAsKHvT5_INMU"
};
export default ___CSS_LOADER_EXPORT___;
